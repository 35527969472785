const currentDate = new Date();
const columns = [];

// Month 1
const month1 = new Date(currentDate);
columns.push({
    title: `${monthName(month1.getMonth())}-${month1.getFullYear()}`,
});

// Month 2
const month2 = new Date(currentDate);
month2.setMonth(month2.getMonth() - 1);
if (month2.getMonth() < 0) {
    month2.setFullYear(month2.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month2.getMonth())}-${month2.getFullYear()}`,
});

// Month 3
const month3 = new Date(currentDate);
month3.setMonth(month3.getMonth() - 2);
if (month3.getMonth() < 0) {
    month3.setFullYear(month3.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month3.getMonth())}-${month3.getFullYear()}`,
});

// Month 4
const month4 = new Date(currentDate);
month4.setMonth(month4.getMonth() - 3);
if (month4.getMonth() < 0) {
    month4.setFullYear(month4.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month4.getMonth())}-${month4.getFullYear()}`,
});

// Month 5
const month5 = new Date(currentDate);
month5.setMonth(month5.getMonth() - 4);
if (month5.getMonth() < 0) {
    month5.setFullYear(month5.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month5.getMonth())}-${month5.getFullYear()}`,

});

// Month 6
const month6 = new Date(currentDate);
month6.setMonth(month6.getMonth() - 5);
if (month6.getMonth() < 0) {
    month6.setFullYear(month6.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month6.getMonth())}-${month6.getFullYear()}`,

});

// Month 7
const month7 = new Date(currentDate);
month7.setMonth(month7.getMonth() - 6);
if (month7.getMonth() < 0) {
    month7.setFullYear(month7.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month7.getMonth())}-${month7.getFullYear()}`,

});

// Month 8
const month8 = new Date(currentDate);
month8.setMonth(month8.getMonth() - 7);
if (month8.getMonth() < 0) {
    month8.setFullYear(month8.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month8.getMonth())}-${month8.getFullYear()}`,
});

// Month 9
const month9 = new Date(currentDate);
month9.setMonth(month9.getMonth() - 8);
if (month9.getMonth() < 0) {
    month9.setFullYear(month9.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month9.getMonth())}-${month9.getFullYear()}`,
});

// Month 10
const month10 = new Date(currentDate);
month10.setMonth(month10.getMonth() - 9);
if (month10.getMonth() < 0) {
    month10.setFullYear(month10.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month10.getMonth())}-${month10.getFullYear()}`,
});

// Month 11
const month11 = new Date(currentDate);
month11.setMonth(month11.getMonth() - 10);
if (month11.getMonth() < 0) {
    month11.setFullYear(month11.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month11.getMonth())}-${month11.getFullYear()}`,
});

// Month 12
const month12 = new Date(currentDate);
month12.setMonth(month12.getMonth() - 11);
if (month12.getMonth() < 0) {
    month12.setFullYear(month12.getFullYear() - 1);
}
columns.push({
    title: `${monthName(month12.getMonth())}-${month12.getFullYear()}`,
});

function monthName(monthIndex) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
}


export default columns;