/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
//import Table from "../../EntryFile/datatable"
//import TableFive from "../../EntryFile/datatable5"
import DatatableCampaign from '../../EntryFile/datatableCampaign'
import Tabletop from "../../EntryFile/tabletop"
import { Oval } from 'react-loader-spinner';
import axios from "axios"
import {
  search_whites,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import baseURL from '../../assets/constant/baseURL'
import columns from "../../assets/constant/contants";
import { useEffect } from "react";
import useAuthStore from "../../InitialPage/AuthStore";


const BrandList = () => {
  const [inputfilter, setInputfilter] = useState(true);
  const [loading, setLoading] = useState(false);
  const options = [
    { id: 1, text: "Last 30 days", nos: "Last 30 days" },
    { id: 2, text: "Last Month", nos: "Last Month" },
    { id: 3, text: " Last 3 Months", nos: " Last 3 Months" },
    { id: 4, text: " Last 6 Months", nos: " Last 6 Months" },
    { id: 5, text: " Last 12 Months", nos: " Last 12 Months" },
  ];

  // const options2 = [

  //   { id: 1, text: "50", text: "50", },
  //   { id: 2, text: "100", text: "100" },
  //   { id: 3, text: " 150", text: " 150" },
  //   { id: 4, text: " 200", text: " 200" },
  //   { id: 5, text: " 250", text: " 250" },
  //   { id: 6, text: " 300", text: " 350" },
  //   { id: 7, text: " 400", text: " 450" },
  //   { id: 8, text: " 500", text: " 550" },
  //   { id: 9, text: " 1000", text: " 1000" },
  // ];



  const togglefilter = (value) => {
    setInputfilter(value);
  };

  useEffect(() => {
    if (accountId) {
      getCampaignData();
      getFilteredCampaignData()
      getTop5Data();
    } else {
      alert("Please select any account to get report")
    }

  }, [])

  // const options3 = [
  //   { id: 1, text: "open_rate", text: "open_rate", },
  //   { id: 2, text: "click_rate", text: "click_rate" },
  //   { id: 3, text: " conversion_rate", text: " conversion_rate" },
  // ];
  const [value, setValue] = useState(20);
  const [CampaignData, setCampaignData] = useState([]);
  const { accountId, isClient, accountData } = useAuthStore((state) => ({
    accountId: state.accountId,
    isClient: state.isClient,
    accountData: state.accountData
  }));
  const getCampaignData = async () => {
    await axios.get(`${baseURL}/api/v1/campaign/${accountId}`)
      .then(response => {

        const campaigns = response.data.data;
        console.log("campaigns", campaigns)
        setCampaignData(campaigns)
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
  }
  const [select, setSelect] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  // const [topfiveData, setTopFiveData] = useState([]);
  //const [topFiveFiletr, setTopFiveFilter] = useState();

  const getFilteredCampaignData = async () => {
    setLoading(true);
    const selected = parseInt(select);
    console.log("select",selected)
    setFilteredData([])
    console.log("resipentFilter", value);
    let filter ;
    if (selected === 1) {
      filter = 30;
    } else if (selected === 2) {
      filter = 1
    } else if (selected === 3) {
      filter = 3
    } else if (selected === 4) {
      filter = 6
    } else if (selected === 5) {
      filter = 12
    } else{
      filter = 30
    }

    console.log("filter",filter)
    await axios.get(`${baseURL}/api/v1/filteredCampaign/${accountId}/${filter}/${value}`)
      .then(response => {
        const campaigns = response.data.data;
        console.log("getFilteredCampaignData", campaigns);
        const sortCampaignsBySentTime = (campaigns) => {
          return campaigns.sort((a, b) => new Date(b.send_time) - new Date(a.send_time));
        };
        const sortedCampaigns = sortCampaignsBySentTime(campaigns);
        setFilteredData(sortedCampaigns)
        setLoading(false)
        console.log(sortCampaignsBySentTime)
      })
      .catch(error => {
        console.error('Error:', error);
        alert("SOMETHING WENT WRONG")
        setLoading(false)
      });
  }

  const getTop5Data = async () => {
    console.log(select)
    console.log("resipentFilter", value);
    let filter = 30
    if (select === "Last Month") {
      filter = 1;
    } else if (select === "Last 3 Months") {
      filter = 3
    } else if (select === "Last 6 Months") {
      filter = 6
    } else if (select === "Last 12 Months") {
      filter = 12
    } else {
      filter = 30
    }
    setLoading(true)
    await axios.get(`${baseURL}/api/v1/gettop5data/${accountId}/${filter}/${value}`)
      .then(response => {
        const campaigns = response.data.data;
        console.log("getFilteredCampaignData", campaigns);
        const sortCampaignsBySentTime = (campaigns) => {
          return campaigns.sort((a, b) => new Date(b.send_time) - new Date(a.send_time));
        };
        // const sortedCampaigns = sortCampaignsBySentTime(campaigns);
        // setTopFiveData(sortedCampaigns)
        setLoading(false)
        console.log(sortCampaignsBySentTime)
      })
      .catch(error => {
        console.error('Error:', error);
        alert("SOMETHING WENT WRONG")
        setLoading(false)
      });
  }


  // const filterFunction = async () => {
  //   console.log(topFiveFiletr);
  //   const sortedData = topfiveData.sort((a, b) => b[topFiveFiletr] - a[topFiveFiletr]);
  //   const topFiveItems = sortedData.slice(0, 5);
  //   setTopFiveData(topFiveItems)
  // }



  const columns1 = [

    // {
    //   title: "Campaign",
    //   dataIndex: "campaignId",
    //   sorter: (a, b) => a.campaignId - b.campaignId,
    // },
    {
      title: "Subject",
      dataIndex: "name",
      render: (text, record) => (
        <a>
          <a href={`https://www.klaviyo.com/campaign/${record?.campaignId}/web-view`} target="_blank" rel="noopener noreferrer">
            {record.name}
          </a>
        </a>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
    },

    {
      title: "Date",
      dataIndex: "send_time",
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleString(); // Adjust the formatting as needed
      },
      sorter: (a, b) => new Date(a.send_time) - new Date(b.send_time),
    },
    {
      title: "Recipients",
      dataIndex: ["statistics", "recipients"],
      sorter: (a, b) => a.statistics.recipients - b.statistics.recipients
    },
    {
      title: "Opens",
      dataIndex: ["statistics", "opens_unique"],
      render: (text, record) => `${record.statistics.opens_unique} (${(record.statistics.open_rate * 100).toFixed(2)}%)`,
      sorter: (a, b) => a.statistics.open_rate - b.statistics.open_rate
    },
    {
      title: " Clicks",
      dataIndex: ["statistics", "clicks_unique"],
      render: (text, record) => `${record.statistics.clicks_unique} (${(record.statistics.click_to_open_rate * 100).toFixed(2)}%)`,
      sorter: (a, b) => a.statistics.click_to_open_rate - b.statistics.click_to_open_rate
    },
    {
      title: "Placed Orders",
      dataIndex: ["statistics", "conversion_uniques"],
      render: (text, record) => `${record.statistics.conversion_uniques} (${((record.statistics.conversion_uniques / record.statistics.clicks_unique) * 100).toFixed(2)}%)`,
      sorter: (a, b) => {
        const rateA = a.statistics.conversion_uniques / a.statistics.clicks_unique;
        const rateB = b.statistics.conversion_uniques / b.statistics.clicks_unique;
        return rateA - rateB;
      }
    },
    {
      title: "Revenue",
      dataIndex: ["statistics", "conversion_value"],
      render: (record) => (
        <div>
          $ {record}
        </div>
      ),
      sorter: (a, b) => a.statistics.conversion_value - b.statistics.conversion_value
    },
  ];

  // const columns2 = [

  //   // {
  //   //   title: "Campaign",
  //   //   dataIndex: "campaignId",
  //   //   sorter: (a, b) => a.campaignId - b.campaignId,
  //   // },
  //   {
  //     title: "Subject",
  //     dataIndex: "name",
  //     render: (text, record) => (
  //       <a>
  //         <a href={`https://www.klaviyo.com/campaign/${record?.campaignId}/web-view`} target="_blank" rel="noopener noreferrer">
  //           {record.name}
  //         </a>
  //       </a>
  //     ),
  //     sorter: (a, b) => a.name.localeCompare(b.name),
  //   },

  //   {
  //     title: "Date",
  //     dataIndex: "send_time",
  //     render: (text) => {
  //       const date = new Date(text);
  //       return date.toLocaleString(); // Adjust the formatting as needed
  //     },
  //     sorter: (a, b) => new Date(a.send_time) - new Date(b.send_time),
  //   },
  //   {
  //     title: "Recipients",
  //     dataIndex: ["statistics", "recipients"],
  //     sorter: (a, b) => a.statistics.recipients - b.statistics.recipients
  //   },
  //   {
  //     title: "Opens Rate",
  //     dataIndex: ["statistics", "opens_unique"],
  //     render: (text, record) => `${record.statistics.opens_unique} (${(record.statistics.open_rate * 100).toFixed(2)}%)`,
  //     sorter: (a, b) => a.statistics.open_rate - b.statistics.open_rate
  //   },
  //   {
  //     title: " Clicks Rate",
  //     dataIndex: ["statistics", "clicks_unique"],
  //     render: (text, record) => `${record.statistics.clicks_unique} (${(record.statistics.click_rate * 100).toFixed(2)}%)`,
  //     sorter: (a, b) => a.statistics.click_rate - b.statistics.click_rate
  //   },
  //   {
  //     title: "Placed Orders Rate",
  //     dataIndex: ["statistics", "conversion_uniques"],
  //     render: (text, record) => `${record.statistics.conversion_uniques} (${((record.statistics.conversion_uniques / record.statistics.clicks_unique) * 100).toFixed(2)}%)`,
  //     sorter: (a, b) => {
  //       const rateA = a.statistics.conversion_uniques / a.statistics.clicks_unique;
  //       const rateB = b.statistics.conversion_uniques / b.statistics.clicks_unique;
  //       return rateA - rateB;
  //     }
  //   },

  //   {
  //     title: "Revenue",
  //     dataIndex: ["statistics", "conversion_value"],
  //     render: (record) => (
  //       <div>
  //         $ {record}
  //       </div>
  //     ),
  //     sorter: (a, b) => a.statistics.conversion_value - b.statistics.conversion_value
  //   },
  // ];

  const handleChange = (event) => {
    const inputValue = event.target.value;
    // Allow only digits
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setValue(numericValue);
    console.log("text", numericValue);
  };


  return (
    <>
      <div className="page-wrapper">
        <div className="content">

          {loading && (
            <div className="loading-overlay">
              <Oval
                height={80}
                width={80}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
          <div className="page-header">
            <div className="page-title">
              {
                !isClient ? (<h4>Overall Campaigns Performance: {accountData.organization_name}</h4>) : (<h4>Overall Campaigns Performance:</h4>)
              }

              {/* <h6>Manage your Reports</h6> */}
            </div>
            {/* <div className="page-btn">
              <Link
                to="/kleanify/product/addproduct-product"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Product
              </Link>
            </div> */}
          </div>
          {/* /product list */}


          <div className="card">
            <div className="card-body">
              {/* <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              /> */}
              {/* /Filter */}
              {/* <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Filter */}
              <div className={loading ? 'content blur ' : 'table-responsive'}>
                <div className="table-responsive">
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th> </th>
                        {
                          columns && columns.map((value, index) => (
                            <th key={index}>{value.title}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <th >Campaign Recipients</th>

                        {CampaignData && CampaignData?.CampaignRecipients?.map((data, index) => (
                          <td key={index}>{data?.toLocaleString()}</td>
                        ))
                        }

                      </tr>

                      <tr>
                        {CampaignData && CampaignData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>
                      <tr>
                        <th>Campaign Opens</th>

                        {CampaignData && CampaignData?.opens_unique?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Campaign Clicks</th>

                        {CampaignData && CampaignData?.clicks_unique?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        {CampaignData && CampaignData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Campaign Placed Orders</th>

                        {CampaignData && CampaignData?.conversion_uniques?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Campaign Revenue</th>

                        {CampaignData && CampaignData?.CampaignRevenue?.map((data, index) => (
                          <td key={index}>$ {data}</td>
                        ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="page-header">
            <div className="page-title">
              <h4>All Campaigns :</h4>
              <h6>Filters of Last 30 days, Last Month, Last 3 Months, Last 6 months, Last 12 months (default last 30 days) in the below table</h6>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              />
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              value={select}
                              onSelect={(value) => setSelect(value.target[value.target.selectedIndex].value)}
                              options={{
                                placeholder: "Select No of Months",
                              }}
                            />
                          </div>

                        </div>

                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <input
                            value={value}
                                onChange={handleChange}
                              className="form-control form-control-sm search-icon"
                              type="search"
                              placeholder="Select Minimum No of Recipent..."
                            />
                          </div>

                        </div>
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img onClick={() => getFilteredCampaignData()} src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <DatatableCampaign columns={columns1} dataSource={filteredData} />
              </div>


            </div>
          </div>


          {/* <div className="page-header">
            <div className="page-title">
              <h4>Top 5 Campaigns :</h4>
              <h6>Filters of Last 30 days, Last Month, Last 3 Months, Last 6 months, Last 12 months (default last 30 days) in the below table</h6>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              />
       
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              value={select}
                              onSelect={(value) => setSelect(value.target[value.target.selectedIndex].text)}
                              options={{
                                placeholder: "Select No of Months",
                              }}
                            />
                          </div>

                        </div>

                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options2}
                              value={resipentFilter}
                              onSelect={(value) => setResipientFilter(value.target[value.target.selectedIndex].text)}
                              options={{
                                placeholder: "Select Minimum No of Recipent",
                              }}
                            />
                          </div>

                        </div>

                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img onClick={() => { getTop5Data() }} src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
         

              <div className="table-responsive">
                <TableFive columns={columns2} dataSource={topfiveData} />
              </div>

            </div>
          </div> */}


        </div>
      </div>
    </>
  );
};
export default BrandList;
