/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Logo,
  SmallLogo,
  Logout,
  LogoWhite,
  Avatar1,
} from "../../EntryFile/imagePath";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import useAuthStore from '../AuthStore';
import { useHistory } from 'react-router-dom';
const Header = (props) => {
  const [toggle, SetToggle] = useState(false);
  const logout = useAuthStore((state) => state.logout);
  const history = useHistory();
  const { isClient , accountData } = useAuthStore((state) => ({
    isClient: state.isClient,
    accountData : state.accountData
  }));

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document.querySelector(".main-wrapper").classList.toggle("slide-nav");
    document.querySelector(".sidebar-overlay").classList.toggle("opened");
    document.querySelector("html").classList.toggle("menu-opened");
  };
  const handleLogout = async () => {
    console.log("CLICKED")
    localStorage.clear();
    logout();
    history.replace('/signIn'); // Use replace instead of push
  };
  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/kleanify/index-three",
    "/reactjs/template/kleanify/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const toggleFullscreen = (elem) => {
    elem = elem || document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to="/kleanify/users/userlists" className="logo logo-normal">
            <img src="https://cdn.lindoai.com/c/recRyFkJQ2fVyQEcM/images/logo-full-256x.png" alt="" />
          </Link>
          <Link to="/kleanify/users/userlists" className="logo logo-white">
            <img src="https://cdn.lindoai.com/c/recRyFkJQ2fVyQEcM/images/logo-full-256x.png" alt="" />
          </Link>
          <Link to="/kleanify/users/userlists" className="logo-small">
            <img src="https://cdn.lindoai.com/c/recRyFkJQ2fVyQEcM/images/logo-full-256x.png" alt="" />
          </Link>
          <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                  ? "none"
                  : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-searchinputs">
            {/* <div className="top-nav-search">
              <Link to="#" className="responsive-search">
                <i className="fa fa-search" />
              </Link>
              <form action="#">
                <div className="searchinputs">
                  <input type="text" placeholder="Search" />
                  <div className="search-addon">
                    <span>
                      <i data-feather="search" className="feather-14" />
                      <FeatherIcon icon="search" className="feather-14" />
                    </span>
                  </div>
                </div>
                <Link class="btn"  id="searchdiv"><img src={HeaderSearch} alt="img"/></Link>
              </form>
            </div> */}
          </li>
          {/* /Search */}
          {/* Flag */}

          {/* /Flag */}
          <li className="nav-item nav-item-box">
            <Link
              to="#"
              id="btnFullscreen"
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}
            >
              {/* <i data-feather="maximize" /> */}
              <FeatherIcon icon="maximize" />
            </Link>
          </li>

          {/* Notifications */}

          {/* /Notifications */}

          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                {/* <span className="user-letter">
                  <img src={Avatar1} alt="" className="img-fluid" />
                </span> */}
                <span className="user-detail">
                  {/* <span className="user-name">Account</span> */}
                  {isClient ? ( <span className="user-name">{accountData?.organization_name || "Account"}</span>): ( <span className="user-name">Kleanify Admin</span>)}
                 
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  {/* <span className="user-img">
                    <img src={Avatar1} alt="" />
                    <span className="status online" />
                  </span> */}
                  {/* <div className="profilesets">
                    {isClient ? ( <h6>{accountData?.organization_name || "Account"}</h6>): ( <h6>Kleanify Admin</h6>)}
                   
                    <h5>Super Admin</h5>
                  </div> */}
                </div>
                <hr className="m-0" />

                {
                  !isClient ? (
                    <>
                      <Link
                        className="dropdown-item"
                        to="/kleanify/users/userlists"
                      >
                        <i className="me-2" data-feather="user" /> Accounts
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/kleanify/users/newuser"
                      >
                        <i className="me-2" data-feather="settings" />
                        Add New
                      </Link>
                    </>

                  ) : (null)
                }

                <hr className="m-0" />
                <a onClick={() => handleLogout()} className="dropdown-item logout pb-0">
                  <img src={Logout} className="me-2" alt="img" />
                  Logout
                </a>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {/* <Link className="dropdown-item" to="profile.html">
              My Profile
            </Link>
            <Link className="dropdown-item" to="generalsettings.html">
              Settings
            </Link> */}
            <a onClick={() => handleLogout()} className="dropdown-item" to="signin.html">
              Logout
            </a>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
