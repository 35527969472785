/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import FeatherIcon from "feather-icons-react";
import useAuthStore from "../AuthStore";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [path, setPath] = useState("");
  const history = useHistory();
  const { accountId, isClient } = useAuthStore((state) => ({
    accountId: state.accountId,
    isClient: state.isClient,
  }));

  const handleOnClick = () => {
    alert("Select Any Account to get Report")
  }

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const pageRefresh = (url, page) => {
    history.push(`/kleanify/${url}/${page}`);
    window.location.reload();
  };
  const location = useLocation();
  let pathname = location.pathname;

  useEffect(() => {
    document.querySelector(".main-wrapper").classList.remove("slide-nav");
    document.querySelector(".sidebar-overlay").classList.remove("opened");
    document.querySelector(".sidebar-overlay").onclick = function () {
      this.classList.remove("opened");
      document.querySelector(".main-wrapper").classList.remove("slide-nav");
    };
  }, [pathname]);
  const exclusionArray = [
    "/reactjs/template/kleanify/index-three",
    "/reactjs/template/kleanify/index-four",
    "/reactjs/template/kleanify/index-two",
    "/reactjs/template/kleanify/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  console.log(accountId, "accountId")

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
              onMouseOver={expandMenuOpen}
              onMouseLeave={expandMenu}
            >
              <ul>
                {!isClient ? (<li className="submenu-open">
                  <h6 className="submenu-hdr">Account Management</h6>
                  <ul>
                    <li className="submenu">
                      <Link
                        to="#"
                        className={
                          pathname.includes("/kleanify/users")
                            ? "subdrop active"
                            : "" || isSideMenu == "Users"
                              ? "subdrop active"
                              : ""
                        }
                        onClick={() =>
                          toggleSidebar(isSideMenu == "Users" ? "" : "Users")
                        }
                      >
                        <FeatherIcon icon="users" />
                        <span>Manage Accounts</span>{" "}
                        <span className="menu-arrow" />
                      </Link>
                      {isSideMenu == "Users" ? (
                        <ul>
                          <li>
                            <Link
                              to="/kleanify/users/newuser"
                              className={
                                pathname.includes("newuser") ? "active" : ""
                              }
                            >
                              New Account{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/kleanify/users/userlists"
                              className={
                                pathname.includes("userlists") ? "active" : ""
                              }
                            >
                              Accounts List
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>   </ul>
                </li>) : (null)}





                <li className="submenu-open">
                  <h6 className="submenu-hdr">Dashboard</h6>
                  <ul>
                    <li
                      className={
                        pathname.includes("dashboard") ? "active" : ""
                      }
                    >
                      {accountId ? (
                        <Link
                          className={pathname.includes("dashboard") ? "active" : ""}
                          to="/kleanify/report/dashboard"
                        >
                          <FeatherIcon icon="box" />
                          <span>Report Page</span>
                        </Link>
                      ) : (
                        <a onClick={handleOnClick}
                        >
                          <FeatherIcon icon="box" />
                          <span>Report Page</span>
                        </a>
                      )}
                    </li>
                    <li
                      className={
                        pathname.includes("flow-performance")
                          ? "active"
                          : ""
                      }
                    >

                      {accountId ? (
                        <Link
                          className={
                            pathname.includes("flow-performance") ? "active" : ""
                          }
                          to="/kleanify/report/flow-performance"
                        >
                          <FeatherIcon icon="codepen" />
                          <span>Flow Performance</span>
                        </Link>
                      ) : (
                        <a onClick={handleOnClick}
                        >
                          <FeatherIcon icon="box" />
                          <span>Flow Performance</span>
                        </a>
                      )}

                    </li>
                    <li
                      className={
                        pathname.includes("campaign-performance") ? "active" : ""
                      }
                    >
                      {accountId ? (
                        <Link
                          className={
                            pathname.includes("campaign-performance") ? "active" : ""
                          }
                          to="/kleanify/report/campaign-performance"
                        >
                          {/* <i data-feather="tag" /> */}
                          <FeatherIcon icon="tag" />
                          <span>Campaign Report</span>
                        </Link>
                      ) : (
                        <a onClick={handleOnClick}
                        >
                          <FeatherIcon icon="box" />
                          <span>Campaign Report</span>
                        </a>
                      )}

                    </li>
                    {/* <li
                      className={
                        pathname.includes("subcategorytable-product")
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        className={
                          pathname.includes("subcategorytable-") ? "active" : ""
                        }
                        to="/kleanify/report/list-growth"
                      >
                        <FeatherIcon icon="speaker" />
                        <span>List Growth</span>
                      </Link>
                    </li> */}




                  </ul>
                </li>


              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default withRouter(Sidebar);
