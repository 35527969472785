/* eslint-disable react/prop-types */
import React from "react";
import { Route, withRouter } from "react-router-dom";

import routerService from "../../Router";
import Header from "./Header";
import Sidebar from "./Sidebar"
import useAuthStore from "../AuthStore";

const DefaultLayout = (props) => {
  const { match } = props;

  const { isClient } = useAuthStore((state) => ({
    isClient: state.isClient,
  }));


  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div>
          {routerService &&
            routerService
              .filter(route => !(isClient && route.path === 'users')) // Filter routes based on isClient condition
              .map((route, key) => (
                <Route
                  key={key}
                  path={`${match.url}/${route.path}`}
                  component={route.component}
                />
              ))}
        </div>
        <Sidebar />
      </div>
      <div className="sidebar-overlay"></div>
    </>
  );
}

export default withRouter(DefaultLayout);
