/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
//import Table from "../../EntryFile/datatable"
//import Tabletop from "../../EntryFile/tabletop"
import axios from "axios"

import columns from "../../assets/constant/contants";
import "react-select2-wrapper/css/select2.css";
import baseURL from '../../assets/constant/baseURL'
import { useEffect } from "react";
import useAuthStore from "../../InitialPage/AuthStore";
import { Oval } from "react-loader-spinner";
const ProductList = () => {
  // const [inputfilter, setInputfilter] = useState(false);
  const { accountId, isClient, accountData } = useAuthStore((state) => ({
    accountId: state.accountId,
    isClient: state.isClient,
    accountData: state.accountData
  }));

  const [loading, setLoading] = useState(false);


  // const togglefilter = (value) => {
  //   setInputfilter(value);
  // };

  useEffect(() => {
    getCampaignData();
    getFlowData()
  }, [])



  const [CampaignData, setCampaignData] = useState([]);
  const [flowData, setFlowData] = useState();

  const reloadAccountData = async (data) => {
    console.log("reloadAccountData", data)
    setLoading(true)
    await axios
      .post(`${baseURL}/api/v1/reloadTotal`, data)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alert("Account Data Updation Started , Please Click One time it will take time to update")
        }
        window.location.reload();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error)
        alert("Something Went wrong")

      });
  }

  const getCampaignData = () => {
    if (accountId) {
      setLoading(true);
      axios.get(`${baseURL}/api/v1/report/${accountId}`)
        .then(response => {

          const campaigns = response.data.data;
          console.log("campaigns", campaigns)
          setCampaignData(campaigns)
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          // Handle error
          console.error('Error:', error);
        });
    } else {
      alert("Please Slelect any account to get report")
    }

  }

  const getFlowData = () => {
    if (accountId) {
      setLoading(true)
      axios.get(`${baseURL}/api/v1/flow_Data/${accountId}`)
        .then(response => {

          const flows = response.data.data;

          const sortedFlows = flows
            .filter(flow => flow.statistics.revenue[0] !== undefined)
            .sort((a, b) => b.statistics.revenue[0] - a.statistics.revenue[0]);
          setFlowData(sortedFlows)
          console.log("flows", flows)
          console.log(flowData)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          // Handle error
          console.error('Error:', error);
        });
    } else {
      alert("Please Slelect any account to get report")
    }

  }

  //const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <>
      <div className="page-wrapper">

        {loading && (
          <div className="loading-overlay">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              {
                !isClient ? (
                  <h4>{accountData?.organization_name}</h4>
                ) : (<h4> Report Page</h4>)
              }

              <h6>Manage your Reports</h6>
            </div>
            {/* <div className="page-btn">
              <Link
                to="/kleanify/product/addproduct-product"
                className="btn btn-added"
              >
                <img src={PlusIcon} alt="img" className="me-1" />
                Add New Product
              </Link>
            </div> */}
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              {/* <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              /> */}
              {/* /Filter */}
              {/* <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Filter */}
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th> </th>
                        {
                          columns && columns.map((value, index) => (
                            <th key={index}>{value.title}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <th > <a onClick={() => reloadAccountData(accountData)}>Total Revenue</a></th>
                        {CampaignData && CampaignData?.totalRevenue?.map((data, index) => (
                          <td key={index}>${parseInt(data.totalSum)?.toLocaleString()}</td>
                        ))
                        }

                      </tr>

                      <tr>
                        <th >Email Revenue</th>

                        {CampaignData && CampaignData?.emailRevenueArray?.map((data, index) => (
                          <td key={index}>${data?.value?.toLocaleString()} ({data?.percent}%)</td>
                        ))
                        }

                      </tr>

                      <tr>
                        {CampaignData && CampaignData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>
                      <tr>
                        <th>Campaign Revenue</th>

                        {CampaignData && CampaignData?.campaignRevenueArray?.map((data, index) => (
                          <td key={index}>${(data?.value?.value)?.toLocaleString()} ({data?.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>No. of Campaign Sent</th>

                        {CampaignData && CampaignData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}>{data}</td>
                        ))
                        }
                      </tr>

                      <tr>
                        {CampaignData && CampaignData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Flows Revenue</th>

                        {CampaignData && CampaignData?.flowRevenueArray?.map((data, index) => (
                          <td key={index}>${(data.value?.value)?.toLocaleString()} ({data?.percent}%)</td>
                        ))
                        }
                      </tr>

                      {/* <tr>
                        <th>No of Flow Sent</th>

                        {CampaignData && CampaignData?.noOfFlowArray?.map((data, index) => (
                          <td key={index}>{data}</td>
                        ))
                        }
                      </tr> */}

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>

                            <th>{value.name}</th>


                            {value && value?.statistics?.
                              revenue?.map((data, index) => (
                                <td key={index}>${data?.value?.toLocaleString()} ({data?.percent}%)</td>
                              ))
                            }
                            {/* </a> */}
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};
export default ProductList;
