import create from 'zustand';
import { persist } from 'zustand/middleware';
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = 'your-encryption-key'; // Change this to your own secret key

const encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
};

const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const useAuthStore = create(
  persist(
    (set) => ({
      isAuthenticated: false,
      isClient: false,
      accountId: null,
      accountData: {},
      login: () => set({ isAuthenticated: true }),
      client: () => set({ isClient: true }),
      logout: () => {
        set({ isAuthenticated: false, accountId: null, isClient: false });
        localStorage.removeItem('auth'); // Clear the auth state from local storage
      },
      setAccountId: (accountId) => set({ accountId }),
      setAccount: (accountData) => set({ accountData }),
    }),
    {
      name: 'auth', // name of the item in local storage
      getStorage: () => localStorage, // specify local storage
      serialize: (state) => {
        const serializedState = JSON.stringify({
          ...state,
          timestamp: new Date().getTime(),
        });
        return encrypt(serializedState); // Encrypt the serialized state
      },
      deserialize: (str) => {
        const decryptedStr = decrypt(str); // Decrypt the state string
        const deserializedState = JSON.parse(decryptedStr);
        const currentTime = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000;
        if (currentTime - deserializedState.timestamp > oneDay) {
          return { state: { isAuthenticated: false, accountId: null, isClient: false } }; // Reset state if more than 1 day has passed
        }
        return deserializedState;
      },
    }
  )
);

export default useAuthStore;
