/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./antd.css";
import { itemRender, onShowSizeChange } from "../components/pagination";
import useAuthStore from "../InitialPage/AuthStore";

const DatatableCampaign = ({ props, columns, dataSource }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const { accountId, setAccountId } = useAuthStore((state) => ({
    accountId: state.accountId,
    setAccountId: state.setAccountId,
  }));
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKey changed: ", newSelectedRowKeys[0]);
    setSelectedRowKey(newSelectedRowKeys[0]);
    setAccountId(newSelectedRowKeys[0])
  };
  useEffect(() => {
    setSelectedRowKey(accountId);
  }, [accountId]);
  const rowSelection = {
    type: 'radio', // Change to radio to allow single selection
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
    onChange: onSelectChange,
  };

  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
     // rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: dataSource.length,
        showTotal: (total, range) =>
          ` ${range[0]} to ${range[1]} of ${total} items`,
        showSizeChanger: true,
        onShowSizeChange: onShowSizeChange,
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default DatatableCampaign;
