/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./antd.css";
import { itemRender, onShowSizeChange } from "../components/pagination";
import useAuthStore from "../InitialPage/AuthStore";

const Datatable = ({ columns, dataSource }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { accountId, setAccountId  , setAccount} = useAuthStore((state) => ({
    accountId: state.accountId,
    setAccountId: state.setAccountId,
    setAccount : state.setAccount
  }));

  const onSelectChange = (newSelectedRowKeys, newSelectedRecords) => {
    console.log("selectedRowKey changed: ", newSelectedRowKeys[0]);
    setSelectedRowKey(newSelectedRowKeys[0]);
    setSelectedRecord(newSelectedRecords[0]);
    setAccountId(newSelectedRowKeys[0]);
  };

  useEffect(() => {
    if (accountId) {
      const selected = dataSource.find(record => record.id === accountId);
      setSelectedRowKey(accountId);
      setSelectedRecord(selected);
      setAccount(selected);
    }
  }, [accountId, dataSource]);

  const rowSelection = {
    type: 'radio', // Change to radio to allow single selection
    selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
    onChange: onSelectChange,
  };

  return (
    <>
      <Table
        className="table datanew dataTable no-footer"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: dataSource.length,
          showTotal: (total, range) => ` ${range[0]} to ${range[1]} of ${total} items`,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
        }}
        rowKey={(record) => record.id} // Assuming 'id' is the unique key
      />
    </>
  );
};

export default Datatable;
