/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from "react";
import Table from "../../EntryFile/datatable";
import { Link } from "react-router-dom";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ClosesIcon,
  Excel,
  Filter,
  Pdf,
  Calendar,
  Printer,
  search_whites,
  Search,
  PlusIcon,
  EditIcon,
  DeleteIcon,
} from "../../EntryFile/imagePath";
//import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import axios from "axios";
import baseURL from "../../assets/constant/baseURL";

const UserLists = () => {
  //const [startDate, setStartDate] = useState(new Date());
  //const [inputfilter, setInputfilter] = useState(false);
  // const options = [
  //   { id: 1, text: "Disable", text: "Disable" },
  //   { id: 2, text: "Enable", text: "Enable" },
  // ];
  // const togglefilter = (value) => {
  //   setInputfilter(value);
  // };

  const reloadAccountData = async (data) => {
    console.log("reloadAccountData", data)
    await axios
      .post(`${baseURL}/api/v1/reload30Days`, data)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alert("Account Data Updation Started , Please Click One time it will take time to update")
        }
        window.location.reload();
      })
      .catch((error) => {
        console.log(error)
        alert("Something Went wrong")

      });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "accountName",
      sorter: (a, b) => a.accountName - b.accountName,
    },

    {
      title: "Total Revenue",
      dataIndex: "totalRevenue",
      render: (render) => "$" + parseInt(render).toLocaleString(),
      sorter: (a, b) => a.totalRevenue - b.totalRevenue,
    },
    {
      title: "Email Revenue",
      key: "emailRevenue",
      render: (text, record) => {
        const emailRevenue = parseInt(record.campaignRevenue) + parseInt(record.flowRevenue);
        const totalRevenue = record.totalRevenue || 1; // Avoid division by zero
        const percent = ((emailRevenue / totalRevenue) * 100).toFixed(1);
        return `$${emailRevenue.toLocaleString()} (${percent}%)`;
      },
      sorter: (a, b) => (a.campaignRevenue + a.flowRevenue) - (b.campaignRevenue + b.flowRevenue),
    },
    {
      title: "Campaign Revenue",
      dataIndex: "campaignRevenue",
      render: (value, record) => {
        const totalRevenue = record.totalRevenue || 1; // Avoid division by zero
        const percent = ((parseInt(value) / totalRevenue) * 100).toFixed(1);
        return `$${parseInt(value).toLocaleString()} (${percent}%)`;
      },
      sorter: (a, b) => a.campaignRevenue - b.campaignRevenue,
    },
    {
      title: "Flow Revenue",
      dataIndex: "flowRevenue",
      render: (value, record) => {
        const totalRevenue = record.totalRevenue || 1; // Avoid division by zero
        const percent = ((parseInt(value) / totalRevenue) * 100).toFixed(1);
        return `$${parseInt(value).toLocaleString()} (${percent}%)`;
      },
      sorter: (a, b) => a.flowRevenue - b.flowRevenue,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: (a, b) => a.currency - b.currency,
    },
    {
      title: "Status",
      dataIndex: "",
      render: (text, record) => {
        const isActive = record.flowStatus && record.campaignStatus;
        return isActive ? "Active" : "Fetching..";
      },
      sorter: (a, b) => {
        const aIsActive = a.flowStatus && a.campaignStatus;
        const bIsActive = b.flowStatus && b.campaignStatus;
        if (aIsActive === bIsActive) {
          return 0;
        }
        return aIsActive ? -1 : 1;
      },
    }

    ,
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => (a.id === b.id ? 0 : a.id ? -1 : 1),
    },
    // {
    //   title: "Report Link",
    //   dataIndex: "emailRevenue",
    //   render: () => "Click",
    //   sorter: (a, b) => a.emailRevenue - b.emailRevenue,
    // },
    // {
    //   title: "Created On",
    //   dataIndex: "dateCreated",
    //   sorter: (a, b) => a.On.length - b.On.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    //   render: (text, record) => (
    //     <>
    //       {text === "Active" && (
    //         <span className="badges bg-lightgreen">{text}</span>
    //       )}
    //       {text === "Restricted" && (
    //         <span className="badges bg-lightred">{text}</span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    {
      title: "Action",
      render: (text, record) => (
        <>
          {/* <Link className="me-3" to="newuseredit">
            <img src={EditIcon} alt="img" />
          </Link> */}
          <Link className="me-3 confirm-text" to="#">
            <img onClick={() => deleteData(record.id)} src={DeleteIcon} alt="img" />
          </Link>
        </>
      ),
    },
    {
      title: "Update",
      render: (text, record) => (
        <>
          {/* <Link className="me-3" to="newuseredit">
            <img src={EditIcon} alt="img" />
          </Link> */}
          <Link className="me-3 confirm-text" to="#">
            <img onClick={() => reloadAccountData(record)} src={EditIcon} alt="img" />
          </Link>
        </>
      ),
    },
  ];


  const [data, setData] = useState([]);
  const [filetrData, setFilterData] = useState([]);
  useEffect(() => {
    fetchData();
  }, [])


  const fetchData = async () => {
    await axios.get(`${baseURL}/api/v1/account`).then((res) => {
      console.log(res.data.data);
      setData(res.data.data)
      setFilterData(res.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  const deleteData = async (id) => {
    console.log(id)
    await axios.delete(`${baseURL}/api/v1/account/${id}`).then((res) => {
      console.log(res.data.data);
      if (res.status == 200 || res.status == 201) {
        alert("User Deleted Succesfully")
      }
      window.location.reload();
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleFilter = (text) => {
    console.log(text)
    if (text === "") {
      setFilterData(data); // Reset to initial data if search text is empty
    } else {
      const filteredData = data.filter(item => item.accountName.toLowerCase().includes(text.toLowerCase()));
      setFilterData(filteredData);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Account List</h4>
            <h6>Last 30 Days Data</h6>
          </div>
          <div className="page-btn">
            <Link to="/kleanify/users/newuser" className="btn btn-added">
              <img src={PlusIcon} alt="img" className="me-2" />
              Add Account
            </Link>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            {/* <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <a
                    className={` btn ${inputfilter ? "btn-filter setclose" : "btn-filter"
                      } `}
                    id="filter_search"
                    onClick={() => togglefilter(!inputfilter)}
                  >
                    <img src={Filter} alt="img" />
                    <span>
                      <img src={ClosesIcon} alt="img" />
                    </span>
                  </a>
                </div>
                <div className="search-input">
                  <input
                    onChange={(e) => handleFilter(e.target.value)}
                    className="form-control form-control-sm search-icon"
                    type="text"
                    placeholder="Search..."
                  />
                  <a className="btn btn-searchset">
                    <img src={Search} alt="img" />
                  </a>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src={Pdf} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src={Excel} alt="img" />
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="print"
                    >
                      <img src={Printer} alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* /Filter */}
            {/* <div
              className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
              id="filter_inputs"
              style={{ display: inputfilter ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter User Name" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Phone" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="text" placeholder="Enter Email" />
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <div className="addonset">
                          <img src={Calendar} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <Select2
                        className="select"
                        data={options}
                        options={{
                          placeholder: "Select",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <a className="btn btn-filters ms-auto">
                        <img src={search_whites} alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={filetrData}
                rowKey={(record) => { console.log("HERE", record.id) }}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default UserLists;
