/* eslint-disable no-dupe-keys */
import React, { useState } from "react";
import Tabletop from "../../EntryFile/tabletop"
import axios from "axios"
import {
  search_whites,
} from "../../EntryFile/imagePath";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import baseURL from '../../assets/constant/baseURL'
import { useEffect } from "react";
import columns from "../../assets/constant/contants";
import useAuthStore from "../../InitialPage/AuthStore";
import { Oval } from "react-loader-spinner";

const CategoryList = () => {
  const [loading , setLoading] = useState(false);
  const [inputfilter, setInputfilter] = useState(true);
  const [select, setSelected] = useState();
  const [options, setOption] = useState([
    { id: 1, text: "Choose Product", text: "Choose Product" },
    { id: 2, text: "Macbook pro", text: "Macbook pro" },
    { id: 3, text: "Orange", text: "Orange" },
  ]);
  //select2
  const { accountId ,isClient, accountData} = useAuthStore((state) => ({
    accountId: state.accountId,
    isClient: state.isClient,
    accountData : state.accountData
  }));



  const togglefilter = (value) => {
    console.log(value)
    setInputfilter(value);
  };

  useEffect(() => {
    getFlowData();
    getAllFlowsData();
    //getIndividualFlow();
  }, [])





  const [flowData, setFlowData] = useState();
  const [messages, setMessages] = useState();


  const getIndividualFlow = (flowId) => {
    setLoading(true)
    if (accountId) {
      axios.get(`${baseURL}/api/v1/flow_Data/${accountId}/${flowId}`)
        .then(response => {

          const flows = response.data.data.finalResult;
          const messages = response.data.data.messages;
          console.log("getIndividualFlow", messages)
          setFlowData(flows)
          setMessages(messages)
          setLoading(false)
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });
    } else {
      setLoading(false)
      alert("Select Any Acount to get Report")
    }

  }

  const [allfowsData, setallflowsData] = useState([]);

  const getAllFlowsData = async () => {
    setLoading(true);
    await axios.get(`${baseURL}/api/v1/allflowperformace/${accountId}`)
      .then(response => {
        const campaigns = response.data.data;
        console.log("campaigns", campaigns)
        setallflowsData(campaigns)
      })
      .catch(error => {
        setLoading(false)
        // Handle error
        console.error('Error:', error);
      });
  }

  const getFlowData = () => {
    if (accountId) {
      setLoading(true);
      axios.get(`${baseURL}/api/v1/flow_Data/${accountId}`)
        .then(response => {

          const flows = response.data.data;
          console.log("flows", flows)
          let array = [];
          flows.map((value, index) => {
            array.push({ id: index + 1, text: value.name, flowId: value.flowId })
          })
          console.log("array", array)
          setOption(array);
          setFlowData([])
          console.log(flowData)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          // Handle error
          console.error('Error:', error);
        });
    } else {
      alert("Select Any Acount to get Report")
    }

  }

  const handleSearch = () => {
    console.log(select);
    getIndividualFlow(options[select - 1].flowId)
  }

  //const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <>
      <div className="page-wrapper">

        {loading && (
          <div className="loading-overlay">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}


        <div className="content">
          <div className="page-header">
            <div className="page-title">
              {
                !isClient ? ( <h4>Overall Flows Performance: {accountData.organization_name}</h4>) : ( <h4>Overall Flows Performance:</h4>)
              }
             
              <h6>Manage your  Reports</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              {/* <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              /> */}

              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        {/* <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              value={select}

                              onSelect={(value) =>{
                                console.log(value.target[value.target.selectedIndex].value)
                                setSelected(value.target[value.target.selectedIndex].value)}}
                              options={{
                                placeholder: "Select Flow",
                              }}
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img onClick={() => handleSearch()} src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th> </th>
                        {
                          columns && columns.map((value, index) => (
                            <th key={index}>{value.title}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <th >Flow Recipients</th>

                        {allfowsData && allfowsData?.CampaignRecipients?.map((data, index) => (
                          <td key={index}>{data?.toLocaleString()}</td>
                        ))
                        }

                      </tr>

                      <tr>
                        {allfowsData && allfowsData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>
                      <tr>
                        <th>Flow Opens</th>

                        {allfowsData && allfowsData?.opens_unique?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Flow Clicks</th>

                        {allfowsData && allfowsData?.clicks_unique?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        {allfowsData && allfowsData?.noOfCampaignArray?.map((data, index) => (
                          <td key={index}></td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Flow Placed Orders</th>

                        {allfowsData && allfowsData?.conversion_uniques?.map((data, index) => (
                          <td key={index}>{(data.value)?.toLocaleString()}({data.percent}%)</td>
                        ))
                        }
                      </tr>

                      <tr>
                        <th>Flow Revenue</th>

                        {allfowsData && allfowsData?.CampaignRevenue?.map((data, index) => (
                          <td key={index}>$ {data?.toLocaleString()}</td>
                        ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>










        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Individual Flow Performance: :</h4>
              <h6>Manage your Report</h6>
            </div>
          </div>
          {/* /product list */}
          <div className="card">
            <div className="card-body">
              <Tabletop
                inputfilter={inputfilter}
                togglefilter={togglefilter}
              />
              {/* /Filter */}
              <div
                className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                id="filter_inputs"
                style={{ display: inputfilter ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg col-sm-6 col-12">
                          <div className="form-group">
                            <Select2
                              className="select"
                              data={options}
                              value={select}

                              onSelect={(value) => {
                                console.log(value.target[value.target.selectedIndex].value)
                                setSelected(value.target[value.target.selectedIndex].value)
                              }}
                              options={{
                                placeholder: "Select Flow",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-sm-6 col-12">
                          <div className="form-group">
                            <a className="btn btn-filters ms-auto">
                              <img onClick={() => handleSearch()} src={search_whites} alt="img" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th> </th>
                        {
                          columns && columns.map((value, index) => (
                            <th key={index}>{value.title}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th>Flow Recipients</th>

                            {value && value?.statistics?.recipent?.map((data, index) => (
                              <td key={index}>{data}</td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th>Flow Opens</th>

                            {value && value?.statistics?.opens_unique?.map((data, index) => (
                              <td key={index}>{data.value} ({data.percent}%)</td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th>Flow Clicks</th>

                            {value && value?.statistics?.clicks_unique?.map((data, index) => (
                              <td key={index}>{data.value} ({data.percent}%)</td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th>Flow Placed Orders</th>

                            {value && value?.statistics?.conversion_uniques?.map((data, index) => (
                              <td key={index}>{data.value} ({data.percent}%)</td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th>Flow Revenue</th>

                            {value && value?.statistics?.revenue?.map((data, index) => (
                              <td key={index}>$ {data.toLocaleString()}</td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        flowData && flowData.map((value, index) => (
                          <tr key={index}>
                            <th></th>

                            {value && value?.statistics?.revenue?.map((data, index) => (
                              <td key={index}></td>
                            ))
                            }
                          </tr>
                        ))
                      }

                      {
                        messages && messages.map((value) => (
                          <>
                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th>{data.messageId} Flow Recipients</th>

                                  {data && data?.statistics?.recipent?.map((lastData, index) => (
                                    <td key={index}>{lastData}</td>
                                  ))
                                  }
                                </tr>
                              ))
                            }

                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th>{data.messageId} Flow Opens</th>

                                  {data && data?.statistics?.opens_unique?.map((lastData, index) => (
                                    <td key={index}>{lastData.value} ({lastData.percent}%)</td>
                                  ))
                                  }
                                </tr>
                              ))
                            }

                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th> {data.messageId} Flow Clicks</th>

                                  {data && data?.statistics?.clicks_unique?.map((lastData, index) => (
                                    <td key={index}>{lastData.value} ({lastData.percent}%)</td>
                                  ))
                                  }
                                </tr>
                              ))
                            }

                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th> {data.messageId} Flow Placed Orders</th>

                                  {data && data?.statistics?.conversion_uniques?.map((lastData, index) => (
                                    <td key={index}>{lastData.value} ({lastData.percent}%)</td>
                                  ))
                                  }
                                </tr>
                              ))
                            }

                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th> {data.messageId} Flow Revenue</th>

                                  {data && data?.statistics?.revenue?.map((lastData, index) => (
                                    <td key={index}>$ {lastData.toLocaleString()}</td>
                                  ))
                                  }
                                </tr>
                              ))
                            }

                            {
                              value && value.map((data, index) => (
                                <tr key={index}>
                                  <th> </th>

                                  {data && data?.statistics?.revenue?.map((lastData, index) => (
                                    <td key={index}></td>
                                  ))
                                  }
                                </tr>
                              ))
                            }
                          </>
                        ))
                      }


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div >
    </>
  );
};
export default CategoryList;
