/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { LoginImage, Logo, MailIcon, GoogleIcon, FacebookIcon } from '../EntryFile/imagePath';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAuthStore from './AuthStore';
import axios from 'axios';
import baseURL from '../assets/constant/baseURL';
import { Oval } from 'react-loader-spinner';

const SignIn = (props) => {
    const [eye, seteye] = useState(true);
    const history = useHistory();
    const login = useAuthStore((state) => state.login);
    const client = useAuthStore((state) => state.client);
    const { setAccountId ,setAccount } = useAuthStore((state) => ({
        setAccountId: state.setAccountId,
        setAccount : state.setAccount
    }));
    const onEyeClick = () => {
        seteye(!eye);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .max(50, 'Password must not exceed 20 characters'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const { email, password } = data;
        // Ensure useHistory is imported and used correctly

        if (email === 'admin@123' && password === 'admin@123') {
            login();
            history.push('/kleanify/users/userlists');
        } else {
            try {
                setLoading(true)
                const check = await axios.get(`${baseURL}/api/v1/checkAccount/${password}`);
                console.log(check);

                if (check.data.status) {
                    login();
                    client();
                    setAccountId(password);
                    setAccount(check.data.data)
                    history.push('/kleanify/report/dashboard');
                    setLoading(false)
                } else {
                    alert('Account does not exist');
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                console.error('Error checking account:', error);
                alert('An error occurred while checking the account');
            }
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', true);
            window.location.reload();
        }
    }, []);

    return (
        <>
            <div className="main-wrapper">
                <Helmet>
                    <title>SignIn - Kleanify</title>
                    <meta name="description" content="SignIn page" />
                </Helmet>
                {loading && (
                    <div className="loading-overlay">
                        <Oval
                            height={80}
                            width={80}
                            color="#4fa94d"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                )}
                <div className="account-content">
                    <div className="login-wrapper">
                        <div className="login-content">
                            <div className="login-userset">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="login-logo">
                                        <img src="https://cdn.lindoai.com/c/recRyFkJQ2fVyQEcM/images/logo-full-256x.png" alt="img" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3>Sign In</h3>
                                        <h4>Please login to your account</h4>
                                    </div>
                                    <div className="form-login">
                                        <label>Email</label>
                                        <div className="form-addons">
                                            <input
                                                type="text"
                                                {...register('email')}
                                                className={`${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                            />
                                            <img src={MailIcon} alt="img" />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>
                                    </div>
                                    <div className="form-login">
                                        <label>Password</label>
                                        <div className="pass-group">
                                            <input
                                                type={eye ? "password" : "text"}
                                                className={`${errors.password ? 'is-invalid' : ''}`}
                                                placeholder="Enter your password"
                                                {...register('password')}
                                            />
                                            <span onClick={onEyeClick} className={`fas toggle-password ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                                            <div className="invalid-feedback">{errors.password?.message}</div>
                                        </div>
                                    </div>
                                    {/* <div className="form-login">
                                        <div className="alreadyuser">
                                            <h4>
                                                <Link to="/forgetPassword" className="hover-a">
                                                    Forgot Password?
                                                </Link>
                                            </h4>
                                        </div>
                                    </div> */}
                                    <div className="form-login">
                                        <button className="btn btn-login" type="submit">
                                            Sign In
                                        </button>
                                    </div>
                                </form>
                                {/* <div className="signinform text-center">
                                    <h4>
                                        Don’t have an account?{" "}
                                        <Link to="/signUp" className="hover-a">
                                            Sign Up
                                        </Link>
                                    </h4>
                                </div>
                                <div className="form-setlogin">
                                    <h4>Or sign up with</h4>
                                </div> */}
                                {/* <div className="form-sociallink">
                                    <ul>
                                        <li>
                                            <Link to="/signin">
                                                <img src={GoogleIcon} className="me-2" alt="google" />
                                                Sign Up using Google
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/signin">
                                                <img src={FacebookIcon} className="me-2" alt="facebook" />
                                                Sign Up using Facebook
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="login-img">
                            <img src={LoginImage} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignIn;
