/* eslint-disable no-dupe-keys */
//import React, { useState } from "react";
import Select2 from "react-select2-wrapper";
import { useState } from "react";
import "react-select2-wrapper/css/select2.css";
import baseURL from "../../assets/constant/baseURL";
import axios from "axios";
// import { Upload } from "../../EntryFile/imagePath";

const Newuser = () => {
  // const [passwordShown, setPasswordShown] = useState(false);
  // const [passwordShown1, setPasswordShown1] = useState(false);
  // const togglePassword = () => {
  //   setPasswordShown(!passwordShown);
  // };
  // const togglePassword1 = () => {
  //   setPasswordShown1(!passwordShown1);
  // };

  const [options, setOptions] = useState([]);
  const [name, setName] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [matricId, setMatricId] = useState(null);
  const [matricName , setMatricName] = useState(null);
  const getMatricData = async () => {
    try {
      if (apiKey === null) {
        alert("ENTER API KEY")
        return
      }
      await axios.get(`${baseURL}/api/v1/get_metrics/${apiKey}`).then((res) => {
        console.log("Matric DATA", res.data);
        const array = []
        res.data.map((value, index) => {
          array.push({ id: index + 1, text: value.attributes.name + " " + "(" + value.id + ")", matricId: value.id })
        })
        console.log(array)
        setOptions(array)
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      alert(error)
    }


  }
  const handleSubmit = () => {
    console.log(options[matricId - 1].matricId)
    const matricIdData = options[matricId - 1].matricId
    if (
      name == null ||
      apiKey == null
    ) {
      alert("Please fill in the detail correctly");
    }

    const params = {
      name: name,
      apiKey: apiKey,
      matricId : matricIdData
    }

    axios
      .post(`${baseURL}/api/v1/account`, params)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          alert("Product Added Succesfully")
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error)
        alert("Something Went wrong")

      });
  }
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Account Management</h4>
            <h6>Add/Update Accounts</h6>
          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Name</label>
                  <input onChange={(e) => setName(e.target.value)} placeholder={name} type="text" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>API Key</label>
                  <input onChange={(e) => setApiKey(e.target.value)} placeholder={apiKey} type="text" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Matric</label>
                  <Select2
                    onSelect={(value) => {
                      console.log(value.target[value.target.selectedIndex].value)
                      setMatricName(value.target[value.target.selectedIndex].value)
                      setMatricId(value.target[value.target.selectedIndex].value)
                    }}
                    value={matricName}
                    className="select"
                    data={options}
                    options={{
                      placeholder: "Select",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <a onClick={() => getMatricData()} className="btn btn-submit me-2">
                  Get Matrics
                </a>
                {
                  matricId !== null ? (<a onClick={() => handleSubmit()} className="btn btn-submit me-2">
                    Submit
                  </a>) : (null)
                }
                <a href="#" className="btn btn-cancel">
                  Cancel
                </a>
              </div>

            </div>
          </div>
        </div>
        {/* /add */}
      </div>
    </div>
  );
};

export default Newuser;
